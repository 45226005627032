import React from "react";
import "react-vertical-timeline-component/style.min.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { motion } from "framer-motion";
import {
  FaGraduationCap,
  FaBriefcase,
  FaLanguage,
  FaPlane,
  FaCode,
} from "react-icons/fa";

import { Box, useColorModeValue } from "@chakra-ui/react";

const ScaleWhenHover = ({ children }) => {
  return (
    <motion.div whileTap={{ scale: 1.1 }} whileHover={{ scale: 1.1 }}>
      {children}
    </motion.div>
  );
};

/**
 *
 *  Inline Styling - not recommended but time constraints
 *
 */

export default function MyTimeLine() {
  var boxColor = useColorModeValue("rgb(254,215,215,255)", "rgb(45,55,72,255)");
  var headerColor = useColorModeValue("black", "white");
  var textColor = useColorModeValue("black", "rgb(203,213,224)");
  var iconColor = useColorModeValue("rgb(245, 171, 171)", "rgb(48,52,76)");
  var lineColor = useColorModeValue("red", "white");
  var arrowColor = useColorModeValue("rgb(245, 171, 171)", "rgb(48,52,76)");

  return (
    <VerticalTimeline layout="2-columns" lineColor={lineColor}>
      <Box>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background: "hsla(0, 0%, 0%, 0)",
            color: textColor,
            boxShadow: "none",
          }}
          contentArrowStyle={{ borderRight: "0px" }}
          date="Present"
          iconStyle={{ background: "rgb(42, 192, 255)", color: "#fff" }}
          icon={<FaBriefcase />}
          position={"left"}
        >
          <motion.div
            whileHover={{ scale: 1.2 }}
            style={{
              backgroundColor: boxColor,
              padding: "15px",
              borderRadius: "15px",
              width: "100%",
            }}
          >
            <h3
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                color: headerColor,
              }}
              className="vertical-timeline-element-title"
            >
              Actively looking for work
            </h3>
            <h4
              style={{ fontSize: "15px", fontStyle: "italic" }}
              className="vertical-timeline-element-subtitle"
            >
              Worldwide
            </h4>
            <p>
              I am currently looking for work in Software Development &
              Programming.
            </p>
          </motion.div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background: "hsla(0, 0%, 0%, 0)",
            color: textColor,
            boxShadow: "none",
          }}
          contentArrowStyle={{ borderRight: "0px" }}
          date="Aug 2019 - Present"
          iconStyle={{ background: iconColor, color: "#fff" }}
          icon={<FaCode />}
          position={"right"}
        >
          <motion.div
            whileHover={{ scale: 1.2 }}
            style={{
              backgroundColor: boxColor,
              padding: "15px",
              borderRadius: "15px",
              width: "100%",
            }}
          >
            <h3
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                color: headerColor,
              }}
              className="vertical-timeline-element-title"
            >
              Programming
            </h3>
            <h4
              style={{ fontSize: "15px", fontStyle: "italic" }}
              className="vertical-timeline-element-subtitle"
            >
              Worldwide
            </h4>
            <p>
              I have been working on independent and pair programming projects during my studies and 
              while looking for a full-time position.
            </p>
          </motion.div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background: "hsla(0, 0%, 0%, 0)",
            color: textColor,
            boxShadow: "none",
          }}
          contentArrowStyle={{ borderRight: "0px" }}
          date="Sep 2020 - Jun 2022"
          iconStyle={{ background: iconColor, color: "#fff" }}
          icon={<FaPlane />}
          position={"left"}
        >
          <motion.div
            whileHover={{ scale: 1.2 }}
            style={{
              backgroundColor: boxColor,
              padding: "15px",
              borderRadius: "15px",
              width: "100%",
            }}
          >
            <h3
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                color: headerColor,
              }}
              className="vertical-timeline-element-title"
            >
              InfoSys Operator for the Korean Air Force
            </h3>
            <h4
              style={{ fontSize: "15px", fontStyle: "italic" }}
              className="vertical-timeline-element-subtitle"
            >
              North Jeolla Province, South Korea
            </h4>
            <p>
              During my service, I managed Computer Hardware & Software, Closed
              (intra)-networks, IoT devices, and other pieces of technology.
            </p>
          </motion.div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background: "hsla(0, 0%, 0%, 0)",
            color: textColor,
            boxShadow: "none",
          }}
          contentArrowStyle={{ borderRight: "0px" }}
          date="Jan 2017 - Sep 2020"
          iconStyle={{ background: iconColor, color: "#fff" }}
          icon={<FaLanguage />}
          position={"right"}
        >
          <motion.div
            whileHover={{ scale: 1.2 }}
            style={{
              backgroundColor: boxColor,
              padding: "15px",
              borderRadius: "15px",
              width: "100%",
            }}
          >
            <h3
              style={{
                fontSize: "25px",
                fontWeight: "bold",
                color: headerColor,
              }}
              className="vertical-timeline-element-title"
            >
              Freelance Translator
            </h3>
            <h4
              style={{ fontSize: "15px", fontStyle: "italic" }}
              className="vertical-timeline-element-subtitle"
            >
              Brisbane, Australia
            </h4>
            <p>
              During my studies and briefly after, I boasted my language skills
              by translating Korean to English for many clients such as Hyundai
              Motor Groups, Seoul Metropolitan Government, and more.
            </p>
          </motion.div>
        </VerticalTimelineElement>
        <ScaleWhenHover>
          <VerticalTimelineElement
            iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
            contentArrowStyle={{ arrowColor }}
            icon={<FaGraduationCap />}
          />
        </ScaleWhenHover>
      </Box>
    </VerticalTimeline>
  );
}
