import React from "react";
import Header from "../pages/Header.tsx";
import Blog from "../pages/Blog.tsx";
import Navigation from "../components/Navigation.tsx";
import Skills from "../pages/Skills.tsx";
import Contact from "../pages/Contact.tsx";
import ScrollButton from "../components/ScrollButton.tsx";
import TimeLine from "../components/MyTimeLine.tsx";
import { Element } from "react-scroll";

import "./style.css";

export default function Main() {
  return (
    <div>
      <Navigation className="nav-bar" />
      <Element className="header-class" name="home">
        <Header />
      </Element>
      <ScrollButton className="scroll-button" />
      <Element className="skills-and-about" name="skills">
        <Skills />
        <TimeLine className='skills-badge'/>
      </Element>
      <Element name="blog">
        <Blog />
      </Element>
      <Element name="contact">
        <Contact />
      </Element>
    </div>
  );
}
