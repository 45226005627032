import React from "react";
import TimeLine from "../components/TimeLine.tsx";

export default function Skills() {


  return (
    <div>
      <div className='timeline-box'>
        <TimeLine />
        <div className="skills-animation">
        </div>
      </div>
    </div>
  );
}
