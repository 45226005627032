import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Textarea,
  Tooltip,
  useClipboard,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import {
  BsGithub,
  BsLinkedin,
  BsPerson,
  BsInstagram,
  BsFillFileTextFill,
} from "react-icons/bs";
import { MdEmail, MdOutlineEmail } from "react-icons/md";
import emailjs, { init } from "emailjs-com";
import Lottie from "react-lottie";
import animation from "../assets/animationThree.json";

init("ki6uY4hgTM1ltFRdq");

const FadeUpWhenVisible = ({ children }) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      variants={{
        visible: { y: 0 },
        hidden: { y: 300 },
      }}
    >
      {children}
    </motion.div>
  );
};

export default function Contact() {
  // contact form without backend
  const { hasCopied, onCopy } = useClipboard("justinoh1996@gmail.com");
  const [name, setName] = useState<String>("");
  const [email, setEmail] = useState<String>("");
  const [message, setMessage] = useState<String>("");
  // eslint-disable-next-line
  const [emailSent, setEmailSent] = useState<boolean>(false);

  // handleSubmit
  const submit = () => {
    if (name && isValidEmail(email) && message) {
      const serviceId = "service_e63txot";
      const templateId = "template_b8jryal";
      const userId = "ki6uY4hgTM1ltFRdq";
      const templateParams = {
        name,
        email,
        message,
      };

      emailjs
        .send(serviceId, templateId, templateParams, userId)
        .then((response) => console.log(response))
        .then((error) => console.log(error));

      setName("");
      setEmail("");
      setMessage("");
      setEmailSent(true);
      alert("Thank you! Your message has been sent!");
    } else {
      alert("Please check your email or required fields");
    }
  };

  // regex for checking email

  const isValidEmail = (email) => {
    const regex =
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };

  // useless
  // const openNewTab = (id) => {
  //   let url = linkPortal.id;
  //   window.open(url, "_blank");
  // };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Flex
      bg={useColorModeValue(useColorModeValue("red.100", "gray.900"))}
      align="center"
      justify="center"
      css={{
        // Use my own background image with lotti????
        backgroundImage: useColorModeValue(
          useColorModeValue("gray.100", "gray.900")
        ),
        backgroundAttachment: "fixed",
      }}
      id="contact"
      className="contact-box"
    >
      <Box
        borderRadius="lg"
        m={{ base: 5, md: 16, lg: 10 }}
        p={{ base: 5, lg: 16 }}
      >
        <Box>
          <VStack spacing={{ base: 4, md: 8, lg: 20 }}>
            <Heading
              fontSize={{
                base: "4xl",
                md: "5xl",
              }}
            >
              Contact Me
            </Heading>

            {/* 
                ICON STACK
            */}
            <Stack
              spacing={{ base: 4, md: 8, lg: 20 }}
              direction={{ base: "column", md: "row" }}
            >
              <Stack
                align="center"
                justify="space-around"
                direction={{ base: "row", md: "column" }}
                className="icons-stack"
              >
                <motion.div
                  whileHover={{ scale: 1.8 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Tooltip
                    label={
                      hasCopied ? "Email Copied!" : "Copy Email to Clipboard"
                    }
                    closeOnClick={false}
                    hasArrow
                  >
                    <IconButton
                      aria-label="email"
                      variant="ghost"
                      size="lg"
                      fontSize="3xl"
                      icon={<MdEmail />}
                      _hover={{
                        bg: "blue.500",
                        color: useColorModeValue("white", "gray.700"),
                      }}
                      onClick={onCopy}
                      isRound
                    />
                  </Tooltip>
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.8 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Tooltip label={"Github"} closeOnClick={false} hasArrow>
                    <Link href="https://www.github.com/jhoh96" target="_blank">
                      <IconButton
                        aria-label="github"
                        variant="ghost"
                        size="lg"
                        fontSize="3xl"
                        icon={<BsGithub />}
                        _hover={{
                          bg: "blue.500",
                          color: useColorModeValue("white", "gray.700"),
                        }}
                        isRound
                      />
                    </Link>
                  </Tooltip>
                </motion.div>
                <motion.div
                  whileHover={{ scale: 1.8 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Tooltip label={"Instagram"} closeOnClick={false} hasArrow>
                    <Link
                      href="https://www.instagram.com/_justinwastaken"
                      target="_blank"
                    >
                      <IconButton
                        aria-label="instagram"
                        variant="ghost"
                        size="lg"
                        icon={<BsInstagram size="28px" />}
                        _hover={{
                          bg: "blue.500",
                          color: useColorModeValue("white", "gray.700"),
                        }}
                        isRound
                      />
                    </Link>
                  </Tooltip>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.8 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Tooltip label={"LinkedIn"} closeOnClick={false} hasArrow>
                    <Link
                      href="https://www.linkedin.com/in/joh1996/"
                      target="_blank"
                    >
                      <IconButton
                        aria-label="linkedin"
                        variant="ghost"
                        size="lg"
                        icon={<BsLinkedin size="28px" />}
                        _hover={{
                          bg: "blue.500",
                          color: useColorModeValue("white", "gray.700"),
                        }}
                        isRound
                      />
                    </Link>
                  </Tooltip>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.8 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Tooltip
                    label={"Download Resume"}
                    closeOnClick={false}
                    hasArrow
                  >
                    <Link
                      href="https://drive.google.com/file/d/17WP_TQavO063ncjva1fTQXRr1_aVCBDk/view"
                      target="_blank"
                    >
                      <IconButton
                        aria-label="resume"
                        variant="ghost"
                        size="lg"
                        icon={<BsFillFileTextFill size="28px" />}
                        _hover={{
                          bg: "blue.500",
                          color: useColorModeValue("white", "gray.700"),
                        }}
                        isRound
                      />
                    </Link>
                  </Tooltip>
                </motion.div>
              </Stack>

              {/* 
              
                        Contact Form
              */}

              <FadeUpWhenVisible>
                <Box
                  bg={useColorModeValue("white", "gray.700")}
                  borderRadius="lg"
                  p={8}
                  color={useColorModeValue("gray.700", "whiteAlpha.900")}
                  shadow="base"
                  className="contact-form"
                >
                  <VStack spacing={5}>
                    <FormControl isRequired>
                      <FormLabel>Name</FormLabel>

                      <InputGroup>
                        <InputLeftElement children={<BsPerson />} />
                        <Input
                          type="text"
                          // name="name"
                          value={name}
                          placeholder="Your Name"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </InputGroup>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel>Email</FormLabel>

                      <InputGroup>
                        <InputLeftElement children={<MdOutlineEmail />} />
                        <Input
                          // type="email"
                          // name="email"
                          value={email}
                          placeholder="Your Email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </InputGroup>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Message</FormLabel>
                      <Textarea
                        //   name="message"
                        placeholder="Your Message"
                        rows={6}
                        value={message}
                        resize="none"
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </FormControl>
                    <motion.div whileHover={{ scale: 1.2 }}>
                      <Button
                        colorScheme="green"
                        bg="green.400"
                        color="white"
                        _hover={{
                          bg: "green.500",
                        }}
                        onClick={submit}
                      >
                        Send Message
                      </Button>
                    </motion.div>
                  </VStack>
                  <div className="mailing-animation">
                    <Lottie options={defaultOptions} height={150} width={250} />
                  </div>
                </Box>
              </FadeUpWhenVisible>
            </Stack>
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
}
