import React from "react";
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import websitePic from "../assets/WebsitePic.jpg";
import imageOne from "../assets/Colors.jpg";
import imageTwo from "../assets/UQ.jpg";
import imageThree from "../assets/musicApp.png";
import { FaGithub, FaGithubAlt,  FaCodepen } from "react-icons/fa";

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps["marginTop"];
}

const colors = new Map<string, string>([
  ["React", "teal"],
  ["TypeScript", "blue"],
  ["Java", "orange"],
  ["Python", "yellow"],
  ["JavaScript", "red"],
  ["UX", "purple"],
  ["Research", "green"],
]);

// const FadeInWhenVisible = ({ children }) => {
//   if (window.innerWidth > 900) {
//     return (
//       <motion.div
//         initial="hidden"
//         whileInView="visible"
//         viewport={{ once: true }}
//         transition={{ duration: 0.5 }}
//         variants={{
//           visible: { x: 0 },
//           hidden: { x: -1000 },
//         }}
//       >
//         {children}
//       </motion.div>
//     );
//   }
// };

const FadeUpWhenVisible = ({ children }) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      variants={{
        visible: { y: 0 },
        hidden: { y: 300 },
      }}
    >
      {children}
    </motion.div>
  );
};

// Customize Blog Tags - revised from original to display colours based on tag category
const BlogTags: React.FC<IBlogTags> = (props) => {
  return (
    <HStack spacing={3} marginTop={props.marginTop}>
      {props.tags.map((tag, index) => {
        return (
          <motion.div whileHover={{ scale: 1.2 }}>
            <Tag
              size={"md"}
              variant="solid"
              colorScheme={getColors(tag)}
              key={index}
            >
              {tag}
            </Tag>
          </motion.div>
        );
      })}
    </HStack>
  );
};

const getColors = (tag) => {
  let color = colors.get(tag);
  return color;
};

const ArticleList = () => {
  return (
    <Container maxW={"7xl"} p="12" className="blog">
      <FadeUpWhenVisible>
        <motion.div whileHover={{ scale: 1.1 }}>
          <Heading fontSize="60px" as="h1">
            Projects
          </Heading>
        </motion.div>
        <Box
          marginTop={{ base: "1", sm: "5" }}
          display="flex"
          flexDirection={{ base: "column", sm: "row" }}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flex="1"
            marginRight="3"
            position="relative"
            alignItems="center"
          >
            <Box
              width={{ base: "100%", sm: "85%" }}
              zIndex="2"
              marginLeft={{ base: "0", sm: "5%" }}
              marginTop="5%"
            >
              <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                <motion.div whileHover={{ scale: 1.05 }}>
                  <a
                    href="https://jhoh96.github.io/music-data-fetch/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      borderRadius="lg"
                      src={imageThree}
                      alt="music data app"
                      objectFit="contain"
                    />
                  </a>
                </motion.div>
              </Link>
            </Box>
            <Box zIndex="1" width="100%" position="absolute" height="100%">
              <Box
                bgGradient={useColorModeValue(
                  "radial(red.600 2px, transparent 1px)",
                  "radial(red.200 2px, transparent 1px)"
                )}
                backgroundSize="20px 20px"
                opacity="0.7"
                height="105%"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: "3", sm: "0" }}
          >
            <BlogTags
              tags={["React", "TypeScript", "JavaScript", "UX", "Demo"]}
            />
            <Heading marginTop="1">
              {/* 
              Change this to LINK
              if we need to use it as link */}
              <h1
                textDecoration="none"
                _hover={{ textDecoration: "underline" }}
                href="https://github.com/jhoh96/music-data-fetch"
                target="_blank"
              >
                Musical-Data Application
              </h1>
            </Heading>
            <Text
              as="p"
              marginTop="2"
              color={useColorModeValue("gray.700", "gray.200")}
              fontSize="lg"
            >
              <div style={{ display: "flex", justifyContent: "center", padding: '1%'  }}>
                <motion.div whileHover={{ scale: 1.3 }}>
                  <Link
                    href="https://github.com/jhoh96/music-data-fetch"
                    target="_blank"
                  >
                    <FaGithub style={{ fontSize: 50, textAlign: "center" }} />
                  </Link>
                </motion.div>
                &nbsp; &nbsp;
                <motion.div whileHover={{ scale: 1.3 }}>
                  <Link
                    href="https://jhoh96.github.io/music-data-fetch/"
                    target="_blank"
                  >
                    <FaGithubAlt
                      style={{ fontSize: 50, justifyContent: "center" }}
                    />
                  </Link>
                </motion.div>
              </div>
              <br />
              Musical Data/Search Application with focus on API requests and
              data visualisation. FrontEnd created using (React), UI Library
              (Grommet), Data/Word Visualisation (visx), and more. BackEnd using
              (NodeJS), (Express), and (Heroku) web server.
            </Text>
          </Box>
        </Box>
      </FadeUpWhenVisible>
      <FadeUpWhenVisible>
        <Heading as="h1" marginTop="10">
          &nbsp; &nbsp;
        </Heading>
        <Divider marginTop="5" />
        <Wrap spacing="30px" marginTop="5">
          <WrapItem width={{ base: "100%", sm: "45%", md: "45%", lg: "30%" }}>
            {/*
             ************     FIRST BLOG TAG      ************
             */}
            <Box w="100%">
              <Box borderRadius="lg" overflow="hidden">
                <Link
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                  href="https://codesandbox.io/s/draw-test-90kcim"
                  target="_blank"
                >
                  <Image
                    transform="scale(1.0)"
                    src={imageOne}
                    alt="Visx Line Visualisation"
                    objectFit="contain"
                    width="100%"
                    transition="0.3s ease-in-out"
                    _hover={{
                      transform: "scale(1.1)",
                    }}
                  />
                </Link>
              </Box>
              <BlogTags tags={["TypeScript", "JavaScript", "Demo"]} marginTop="3" />
              <Heading fontSize="xl" marginTop="2">
                <h1
                  textDecoration="none"
                  href="https://github.com/jhoh96/Draw-practice"
                  _hover={{ textDecoration: "underline" }}
                >
                  Line Visualisation/Draw Prac w/ @visx
                </h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5%",
                  }}
                >
                  <motion.div whileHover={{ scale: 1.3 }}>
                    <Link
                      href="https://github.com/jhoh96/Draw-practice"
                      target="_blank"
                    >
                      <FaGithub style={{ fontSize: 35, textAlign: "center" }} />
                    </Link>
                  </motion.div>
                  &nbsp; &nbsp;
                  <motion.div whileHover={{ scale: 1.3 }}>
                    <Link
                      href="https://codesandbox.io/s/draw-test-90kcim"
                      target="_blank"
                    >
                      <FaCodepen
                        style={{ fontSize: 35, justifyContent: "center" }}
                      />
                    </Link>
                  </motion.div>
                </div>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
                Collecting mouse movement & events to map them onto a canvas. A
                paint application sample/mockup.
              </Text>
            </Box>
          </WrapItem>
          <WrapItem width={{ base: "100%", sm: "45%", md: "45%", lg: "30%" }}>
            {/*
             ************     Second BLOG TAG      ************
             */}
            <Box w="100%">
              <Box borderRadius="lg" overflow="hidden">
                <Link
                  textDecoration="none"
                  _hover={{ textDecoration: "underline" }}
                  href="https://github.com/Joh1996/Queensland-Alliance-for-Environmental-Health-Sciences---Final-Report"
                  target="_blank"
                >
                  <Image
                    transform="scale(1.0)"
                    src={imageTwo}
                    alt="UQ Capstone Project"
                    objectFit="contain"
                    width="100%"
                    transition="0.3s ease-in-out"
                    _hover={{
                      transform: "scale(1.1)",
                    }}
                  />
                </Link>
              </Box>
              <BlogTags
                tags={["React", "JavaScript", "UX", "Python", "Research"]}
                marginTop="3"
              />
              <Heading fontSize="xl" marginTop="2">
                <h1
                  textDecoration="none"
                  _hover={{ textDecoration: "underline" }}
                  href="https://github.com/jhoh96/Human-Factors-Research"
                  target="_blank"
                >
                  University Capstone Project
                </h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5%",
                  }}
                >
                  <motion.div whileHover={{ scale: 1.3 }}>
                    <Link
                      href="https://github.com/Joh1996/Queensland-Alliance-for-Environmental-Health-Sciences---Final-Report"
                      target="_blank"
                    >
                      <FaGithub
                        style={{ fontSize: 35, justifyContent: "center" }}
                      />
                    </Link>
                  </motion.div>
                </div>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
                Queensland Alliance for Environmental Health Sciences Web
                Application Project. Code cannot be shared per University
                Guidelines. However, a full report can be found on github.
              </Text>
            </Box>
          </WrapItem>
          <WrapItem width={{ base: "100%", sm: "45%", md: "45%", lg: "30%" }}>
            {/*
             ************     Third BLOG TAG      ************
             */}
            <Box w="100%">
              <Box borderRadius="lg" overflow="hidden">
                <Link
                  textDecoration="none"
                  _hover={{ textDecoration: "underline" }}
                  href="https://justinwastaken.com"
                  target="_blank"
                >
                  <Image
                    transform="scale(1.0)"
                    src={websitePic}
                    alt="Final Project"
                    objectFit="contain"
                    width="100%"
                    transition="0.3s ease-in-out"
                    _hover={{
                      transform: "scale(1.1)",
                    }}
                  />
                </Link>
              </Box>
              <BlogTags
                tags={["React", "TypeScript", "JavaScript", "Resume"]}
                marginTop="3"
              />
              <Heading fontSize="xl" marginTop="2">
                <h1
                  textDecoration="none"
                  _hover={{ textDecoration: "underline" }}
                  href="https://github.com/jhoh96/website-practice"
                  target="_blank"
                >
                  This Website
                </h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5%",
                  }}
                >
                  <motion.div whileHover={{ scale: 1.3 }}>
                    <Link
                      href="https://github.com/jhoh96/website-practice"
                      target="_blank"
                    >
                      <FaGithub style={{ fontSize: 35, textAlign: "center" }} />
                    </Link>
                  </motion.div>
                </div>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
                Simple, single-paged create-react-app using typescript. Here
                I've referenced and utilized many React libraries for simple UI
                (Chakra), data visualization (visx), animation (framer), form
                handling (mailjs), and more. It is a more programming-oriented
                resume for those who are interested.
              </Text>
            </Box>
          </WrapItem>
        </Wrap>
        <Divider marginTop="5" />
      </FadeUpWhenVisible>
    </Container>
  );
};

export default ArticleList;
