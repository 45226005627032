import React from "react";
import { motion } from "framer-motion";
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
  useColorModeValue,
  Flex,
  Divider,
  Image,
  // Center,
} from "@chakra-ui/react";
import { FaGraduationCap, FaLanguage, FaPlane } from "react-icons/fa";
import JSImage from "../assets/javascript.png";
import ReactImage from "../assets/react.png";
import OthersImage from "../assets/others.png";
import JavaImage from "../assets/java.png";
import NodeImage from "../assets/node-js.png";

// Array of features/content I want to display
// Fill with content I need to use
// const timeLineArray = [
//   {
//     icon: FaRegCircle,
//     title: "Jun/2020 - Present",
//     text: "Currently looking for software development job opportunities in Korea, Australia, and the USA.",
//     height: "80%",
//   },
//   {
//     icon: FaRegCircle,
//     title: "Sep/2020 - Jun/2022",
//     text: "Completed military service at the Republic of Korea Air Force.",
//     height: "80%",
//   },
//   {
//     icon: FaRegCircle,
//     title: "Mar/2015 - Jul/2019",
//     text: "Moved to Brisbane, Australia to complete my University Program at The University of Queensland.",
//     height: "80%",
//   },
//   {
//     icon: FaRegCircle,
//     title: "May/2014",
//     text: "Moved to South Korea after graduating high school in the United States of America.",
//     height: "80%",
//   },
// ];

const featureArray = [
  {
    icon: FaGraduationCap,
    title: "University of Queensland Alumnus (Class of 2019)",
    text: "Completed a BSc majoring in Computer Science & Psychology (dual) in UQ Australia.",
  },
  {
    icon: FaPlane,
    title: "Republic of Korea Air Force",
    text: "Served as an Information Systems Operator/Manager for the South Korean Air Force.",
  },
  {
    icon: FaLanguage,
    title: "Native Bilingual",
    text: "Born in South Korea, Raised in the United States, Graduated in Australia. Language is one of my strongest assets.",
  },
];

const skillsArray = [
  {
    skill: "React",
    image: ReactImage,
    background:
      "Create React App, \n Single-Paged Application, and \n Front-end Web Development",
    number: 1,
  },
  {
    skill: "JavaScript",
    image: JSImage,
    background: "React Apps, \n ES6, \n Front-end, TypeScript",
    number: 2,
  },
  {
    skill: "Java",
    image: JavaImage,
    background: "Object-Oriented Programming, MVC, Spring Framework ",
    number: 3,
  },
  {
    skill: "NodeJS",
    image: NodeImage,
    background: "JavaScript Backend, Server-side scripts",
    number: 4,
  },
  {
    skill: "Others",
    image: OthersImage,
    background:
      "Proficiency and/or interest in HTML, CSS(SASS/LESS), Google Firebase, AWS",
    number: 5,
  },
];

// const FadeInWhenVisible = ({ children }) => {
//   if (window.innerWidth > 900) {
//     return (
//       <motion.div
//         initial="hidden"
//         whileInView="visible"
//         viewport={{ once: true }}
//         transition={{ duration: 0.5 }}
//         variants={{
//           visible: { x: 0 },
//           hidden: { x: -1000 },
//         }}
//       >
//         {children}
//       </motion.div>
//     );
//   }
// };

const FadeUpWhenVisible = ({ children }) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      variants={{
        visible: { y: 0 },
        hidden: { y: 300 },
      }}
    >
      {children}
    </motion.div>
  );
};

export default function TimeLine() {
  var changeColor = useColorModeValue("red.400", "gray.100");
  var boxColor = useColorModeValue("red.100", "gray.700");
  var textColor = useColorModeValue("gray.900", "gray.300");

  return (
    <Box p={4} className="timeline-box">
      <Divider marginTop="10" variant="dashed" />
      {/* TOP Heading Area goes here */}
      <Stack spacing={4} as={Container} maxW={"6xl"} textAlign={"center"}>
        <FadeUpWhenVisible>
          <motion.div whileHover={{ scale: 1.1 }}>
            <Heading fontSize={"6xl"}>About Me</Heading>
          </motion.div>
        </FadeUpWhenVisible>
        <FadeUpWhenVisible>
          <Text color={textColor} fontSize={"2xl"}>
            Experiences, Education, and Things I like...
          </Text>
        </FadeUpWhenVisible>
      </Stack>
      {/* Timeline Grid goes here */}
      <FadeUpWhenVisible>
        <Container maxW={"6xl"} mt={10}>
          <SimpleGrid columns={{ base: 1, md: 1 }} spacing={10}>
            <Flex direction="column">
              {/* 
              Key Facts
            */}
              {featureArray.map((feature, index) => (
                <motion.div
                  whileTap={{ scale: 1.1 }}
                  whileHover={{ scale: 1.1 }}
                  key={feature.image}
                >
                  <HStack
                    p={6}
                    key={index}
                    align={"top"}
                    background={boxColor}
                    margin="10px"
                    rounded="lg"
                  >
                    <Box color={changeColor} px={2}>
                      <Icon as={feature.icon} w={6} h={6} />
                    </Box>
                    <VStack align={"start"}>
                      <Text fontWeight={700}>{feature.title}</Text>
                      <Text color={textColor}>{feature.text}</Text>
                    </VStack>
                  </HStack>
                </motion.div>
              ))}
            </Flex>
          </SimpleGrid>
        </Container>

        {/* 
         Cards being mapped ------------------------------------------------------------
        */}
        <Flex py={10} className="skills-badges">
          {skillsArray.map((skill, index) => (
            <motion.div whileTap={{ scale: 1.1 }} whileHover={{ scale: 1.1 }}>
              <Box
                role={"group"}
                p={10}
                maxW={"300px"}
                minW={"250px"}
                minH={"380px"}
                maxH={"380px"}
                bg={boxColor}
                rounded={"lg"}
                pos={"relative"}
                zIndex={1}
                margin="5px"
                key={index}
              >
                <Image
                  rounded={"lg"}
                  height={150}
                  width={152}
                  objectFit={"cover"}
                  src={skill.image}
                />
                <Stack pt={10} align={"center"}>
                  <Text
                    color={textColor}
                    fontSize={"lg"}
                    textTransform={"uppercase"}
                    fontWeight={"bold"}
                  >
                    {skill.skill}
                  </Text>
                  <Heading
                    fontSize={"2xm"}
                    fontFamily={"body"}
                    fontWeight={300}
                    maxW={"150px"}
                  >
                    {skill.background}
                  </Heading>
                  <Stack direction={"row"} align={"center"}></Stack>
                </Stack>
              </Box>
            </motion.div>
          ))}
        </Flex>
      </FadeUpWhenVisible>
      <Divider marginTop="10" variant="dashed" />
    </Box>
  );
}
